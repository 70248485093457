// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconMenu,
    IconBoxMultiple,
    IconCircleOff,
    IconCircle,
    IconBrandGravatar,
    IconShape,
    IconSettingsAutomation,
    IconRouter,
    IconClipboardX,
    IconDeviceIpad,
    IconSettings,
    IconFileSettings,
    IconUsers,
    IconAppWindow,
    IconUserCheck,
    IconUserCircle,
    IconApps,
    IconCashBanknote,
    IconBulb,
    IconLogin,
    IconUserExclamation,
    IconAddressBook,
    IconClick,
    IconPyramid,
    IconKey,
    IconLockAccessOff,
    IconNetwork,
    IconListCheck,
    IconRobot
} from '@tabler/icons';

// constant
const icons = {
    IconMenu,
    IconBoxMultiple,
    IconCircleOff,
    IconCircle,
    IconBrandGravatar,
    IconShape,
    IconSettingsAutomation,
    IconDeviceIpad,
    IconRouter,
    IconClipboardX,
    IconSettings,
    IconFileSettings,
    IconUsers,
    IconAppWindow,
    IconUserCheck,
    IconUserCircle,
    IconApps,
    IconCashBanknote,
    IconBulb,
    IconLogin,
    IconUserExclamation,
    IconAddressBook,
    IconClick,
    IconPyramid,
    IconKey,
    IconLockAccessOff,
    IconNetwork,
    IconListCheck,
    IconRobot
};

// ==============================|| DEVELOPER MENU ITEMS ||============================== //

export const Developer = {
    id: 'Developer',
    title: <FormattedMessage id="Developer" />,
    icon: icons.IconMenu,
    type: 'group',
    children: [
        {
            id: 'pg',
            title: <FormattedMessage id="PG Routers" />,
            type: 'item',
            url: '/developer/pg',
            icon: icons.IconRouter,
            breadcrumbs: false
        },
        {
            id: 'proxy',
            title: <FormattedMessage id="Proxy" />,
            type: 'item',
            url: '/developer/proxy',
            icon: icons.IconClipboardX,
            breadcrumbs: false
        },
        {
            id: 'bank',
            title: <FormattedMessage id="Banks" />,
            type: 'item',
            url: '/developer/bank',
            icon: icons.IconCashBanknote,
            breadcrumbs: false
        },
        {
            id: 'payin-client',
            title: <FormattedMessage id="Merchant Info" />,
            type: 'item',
            url: '/developer/payin-client',
            icon: icons.IconUserCheck,
            breadcrumbs: false
        },
        {
            id: 'ip',
            title: <FormattedMessage id="IP Whitelist" />,
            type: 'item',
            url: '/developer/ip-whitelist',
            icon: icons.IconDeviceIpad,
            breadcrumbs: false
        },
        {
            id: 'merchant-config',
            title: <FormattedMessage id="Merchant Config" />,
            type: 'item',
            url: '/developer/merchant-config',
            icon: icons.IconFileSettings,
            breadcrumbs: false
        },
        {
            id: 'merchant-intent-config',
            title: <FormattedMessage id="Merchant Intent Config" />,
            type: 'item',
            url: '/developer/merchant-intent-config',
            icon: icons.IconAddressBook,
            breadcrumbs: false
        },
        {
            id: 'clickpay-config',
            title: <FormattedMessage id="Clickpay Config" />,
            type: 'item',
            url: '/developer/clickpay-config',
            icon: icons.IconClick,
            breadcrumbs: false
        },
        {
            id: 'merchant-meta-config',
            title: <FormattedMessage id="Merchant Notes" />,
            type: 'item',
            url: '/developer/merchant-meta-config',
            icon: icons.IconUserCircle,
            breadcrumbs: false
        },
        {
            id: 'loggedIn-merchants',
            title: <FormattedMessage id="LoggedIn Merchants" />,
            type: 'item',
            url: '/developer/loggedIn-merchants',
            icon: icons.IconLogin,
            breadcrumbs: false
        },
        {
            id: 'payout-txn',
            title: <FormattedMessage id="Payout Transaction" />,
            type: 'item',
            url: '/developer/payout-txn',
            icon: icons.IconSettings,
            breadcrumbs: false
        },
        {
            id: 'secret',
            title: <FormattedMessage id="Secret" />,
            type: 'item',
            url: '/developer/secret',
            icon: icons.IconSettings,
            breadcrumbs: false
        },

        {
            id: 'affiliates',
            title: <FormattedMessage id="Affiliates" />,
            type: 'item',
            url: '/developer/affiliates',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'urls',
            title: <FormattedMessage id="URLs" />,
            type: 'item',
            url: '/developer/urls',
            icon: icons.IconAppWindow,
            breadcrumbs: false
        },
        {
            id: 'upi-apps',
            title: <FormattedMessage id="UPI Apps" />,
            type: 'item',
            url: '/developer/upi-apps',
            icon: icons.IconApps,
            breadcrumbs: false
        },
        {
            id: 'bulk-webhook',
            title: <FormattedMessage id="Bulk Webhook" />,
            type: 'item',
            url: '/developer/bulk-webhook',
            icon: icons.IconBulb,
            breadcrumbs: false
        },
        {
            id: 'bulk-payment',
            title: <FormattedMessage id="Bulk Payment" />,
            type: 'item',
            url: '/developer/bulk-payment',
            icon: icons.IconPyramid,
            breadcrumbs: false
        },
        {
            id: 'cb-payment',
            title: <FormattedMessage id="Chargeback Settlement" />,
            type: 'item',
            url: '/developer/cb-settlements',
            icon: icons.IconListCheck,
            breadcrumbs: false
        },
        {
            id: 'super-admins',
            title: <FormattedMessage id="Super Admins" />,
            type: 'item',
            url: '/developer/super-admins',
            icon: icons.IconUserExclamation,
            breadcrumbs: false
        },
        {
            id: 'update-password',
            title: <FormattedMessage id="Update Password" />,
            type: 'item',
            url: '/developer/update-password',
            icon: icons.IconKey,
            breadcrumbs: false
        },
        {
            id: 'unauth-locations',
            title: <FormattedMessage id="Unauth Locations" />,
            type: 'item',
            url: '/developer/unauth-locations',
            icon: icons.IconLockAccessOff,
            breadcrumbs: false
        },
        {
            id: 'merchant-vpn',
            title: <FormattedMessage id="Merchant VPN" />,
            type: 'item',
            url: '/developer/merchant-vpn',
            icon: icons.IconNetwork,
            breadcrumbs: false
        },
        {
            id: 'bot',
            title: <FormattedMessage id="Bot Creds" />,
            type: 'item',
            url: '/developer/bot-creds',
            icon: icons.IconRobot,
            breadcrumbs: false
        }
    ]
};

export default Developer;

import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Switch,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Slide,
    TextField,
    MenuItem
} from '@mui/material';

import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import Loader from 'ui-component/Loader';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

const validationSchema = yup.object({
    chatId: yup.string().required('Required Field').nullable(),
    botKey: yup.string().required('Required Field').nullable(),
    groupName: yup.string().required('Required Field').nullable()
});

// ==============================|| Merchant ADD DIALOG ||============================== //

const AddBot = ({ open, handleCloseDialog }) => {
    const dispatch = useDispatch();
    const [details, setDetails] = useState({
        chatId: '',
        botKey: '',
        groupName: '',
        isActive: true
    });

    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        initialValues: details,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            if (data?.id) {
                setLoading(true);
                axiosServices
                    .put('updateChatIdAndBotKey', data)
                    .then((res) => {
                        setLoading(false);
                        resetForm();
                        handleCloseDialog();
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Saved Successfully',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    })
                    .catch((e) => {
                        setLoading(false);
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: e?.message || 'Error while updating details',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch(closeSnackbar);
                    });
            } else {
                setLoading(true);
                axiosServices
                    .post('addChatId', data)
                    .then((res) => {
                        setLoading(false);
                        resetForm();
                        handleCloseDialog();
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Saved Successfully',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    })
                    .catch((e) => {
                        setLoading(false);
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: e?.message || 'Error while updating details',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch(closeSnackbar);
                    });
            }
        }
    });

    useEffect(() => {
        if (open?.id) {
            setDetails(open);
        }
    }, [open]);

    return (
        <Dialog open={open} TransitionComponent={Transition} fullWidth="true" maxWidth="sm" keepMounted>
            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>{open?.id ? 'Edit Bot' : 'Add Bot'}</DialogTitle>
                    <DialogContent>
                        {loading && <Loader />}

                        <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="botKey"
                                    name="botKey"
                                    label="Bot Key"
                                    value={formik.values.botKey}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.botKey)}
                                    helperText={formik.errors.botKey}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="chatId"
                                    name="chatId"
                                    label="Chat ID"
                                    value={formik.values.chatId}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.chatId)}
                                    helperText={formik.errors.chatId}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="groupName"
                                    name="groupName"
                                    label="Group"
                                    value={formik.values.groupName}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.groupName)}
                                    helperText={formik.errors.groupName}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                    labelPlacement="start"
                                    control={
                                        <Switch
                                            size="small"
                                            onChange={(e) => {
                                                formik.setFieldValue('isActive', e.target.checked);
                                            }}
                                            checked={formik.values.isActive}
                                        />
                                    }
                                    label="Active"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <AnimateButton>
                            <Button disabled={loading} type="submit" variant="contained">
                                Save
                            </Button>
                        </AnimateButton>
                        <Button
                            variant="text"
                            color="error"
                            disabled={loading}
                            onClick={() => {
                                formik.resetForm();
                                setDetails({});
                                handleCloseDialog();
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

AddBot.propTypes = {
    open: PropTypes.bool,
    handleCloseDialog: PropTypes.func
};

export default AddBot;
